@mixin breakpoint($point) {
	@if $point == xlarge {
		@media (max-width: $xlarge)  { @content ; }
	}
	@else if $point == large {
		@media (max-width: $large)  { @content ; }
	}
	@else if $point == medium {
		@media (max-width: $medium)  { @content ; }
	}
	@else if $point == small {
		@media (max-width: $small)  { @content ; }
	}
}