.header {
	background-size: cover;
	background-position: center;
	height: 600px;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-bottom: 1rem;
	@include breakpoint(medium) {
		height: 400px;
	}
	@include breakpoint(small) {
		height: 200px;
	}
	.header-gradient {
		height: 140px;
		background: linear-gradient(to bottom, rgba(125,185,232,0) 0%,rgba(0,0,0,0.35) 50%,rgba(0,0,0,0.6) 100%); 
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding-left: 2rem;
		@include breakpoint(small) {
			padding-left: 1rem;
		}
		h1 {
			color: $white;
			font-weight: 100;
			@include breakpoint(small) {
				font-size: 1.6rem;
			}
		}
	}
}

.border-tag {
	display: inline-block;
	padding: 5px 10px;
	margin-bottom: 10px;
	border: 4px solid;
	border-radius: 10px;
	@include breakpoint(medium) {
		display: block;
		width: 60%;
		margin: 0 auto 10px auto;
		text-align: center;
	}

	@include breakpoint(small) {
		width: 100%;
	}
	&.red {
		border-color: $red;
		color: $dark-red;

	}
	&.green {
		border-color: $green;
		color: $dark-green;

	}
	&.blue {
		border-color: $blue;
		color: $dark-blue;
	}
}