*, ::before, ::after {
	box-sizing: border-box;
}

body {
	margin: 0;
	padding: 0;
}

img {
	width: 100%;
	display: block;
}

.container {
	max-width: 1024px;
	margin: 0 auto;
	.content {
		padding: 2rem;
		@include breakpoint(small) {
			padding: 1rem;
		}
		&.home {
			h2 {
				margin-bottom: 0.5rem;
			}
		}
	}
}

.footer {
	display: block;
	text-align: center;
	padding: 4rem 0;
	color: #ccc;
}

.message {
	border-radius: 0 0 4px 4px;
	margin-bottom: 2rem;
	.message-header {
		color: $white;
		font-size: 1.2rem;
		font-weight: bold;
		padding: 0.75rem 2rem;
		border-radius: 4px 4px 0 0;
		@include breakpoint(small) {
			padding: 0.75rem 1rem;
		}
	}
	.message-body {
		padding: 2rem;
		@include breakpoint(small) {
			padding: 1rem;
		}
	}
	&.info {
		background-color: $light-blue;
		.message-header {
			background-color: $blue;
		}
		.message-body {
			color: $dark-blue;
			h3 {
				color: $blue;
			}
		}			
	}
	&.danger {
		background-color: $light-red;
		.message-header {
			background-color: $red;
		}
		.message-body {
			color: $dark-red;
			h3 {
				color: $red;
			}
		}	
	}
}

.columns {
	display: flex;
	flex-wrap: wrap;
	margin-left: -0.75rem;
	margin-right: -0.75rem;
	.column {
		padding: 0.75rem;
		width: 50%;
		@include breakpoint(medium) {
			width: 100%;
			> img {
				width: 50%;
				margin: 0 auto;
			}
			.column{
				width: 50%;
				img {
					width: 100%;
				}
			}
		}
		@include breakpoint(small) {
			> img {
				width: 100%;
			}
			.column{
				width: 100%;
				img {
					width: 100%;
				}
			}
		}
	}
}