@charset "utf-8";
@import "../node_modules/bourbon/core/bourbon";
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

$family-primary: 'Nunito';
$white: #ffffff;

$red: #ff3860;
$light-red: #fff5f7;
$dark-red: #cd0930;

$blue: #3273dc;
$light-blue: #f6f9fe;
$dark-blue: #22509a;

$green: #23d160;
$light-green: #f6fef9;
$dark-green: #107110;

// Break Points
$small : 420px;
$medium : 767px;
$large: 1023px;
$xlarge: 1220px;

@import "_mixins.scss";
@import "_typography.scss";
@import "_base.scss";
@import "_modal.scss";
@import "_entry.scss";