html {
	font-size: 17px;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
}

body {
	font-family: "Nunito";
	font-size: 1rem;
	line-height: 1.5;
	color: #4a4a4a;
}


h1, h2 {
	line-height: 1.2;
}

p {
	margin: 8px 0 24px 0;
}

a {
	color: $blue;
	&:hover {
		color: $dark-blue;
	}
}

.text-bold {
	font-weight: 700;
}